<script>
    import HomeCard from "../common/HomeCard.svelte";
</script>

<section class="w-full flex flex-wrap justify-center items-center gap-3">
    
    <!-- <HomeCard title="Diary Of An Activist" text="" url="/p/diary-of-an-activist" bgImage="diary-of-an-activist"/>
    <HomeCard title="Cork LGBT Archive" text="" url="/p/cork-lgbt-archive" bgImage="cork-lgbt-archive"/>
    <HomeCard title="Queer Republic Of Cork" text="" url="/p/queer-republic-of-cork" bgImage="queer-republic-of-cork"/>
    <HomeCard title="I'm Here, I'm Home, I'm Happy" text="" url="/p/im-here-im-home-im-happy" bgImage="im-here-im-home-im-happy"/>
    <HomeCard title="Cork Queeros" text="" url="/p/cork-queeros" bgImage="cork-queeros"/>
    <HomeCard title="Orla Egan" text="" url="/p/orla" bgImage="orla"/> -->

    <HomeCard title="Diary Of An Activist" text="" url="https://diaryofanactivist.com/" target="_blank" bgImage="diary-of-an-activist"/>
    <HomeCard title="Cork LGBT Archive" text="" url="https://corklgbtarchive.com/" target="_blank" bgImage="cork-lgbt-archive"/>
    <HomeCard title="Queer Republic Of Cork" text="" url="https://buy.stripe.com/fZe8zgaAXcDP9eE7st" target="_blank" bgImage="queer-republic-of-cork"/>
    <HomeCard title="I'm Here, I'm Home, I'm Happy" text="" url="/#/im-here-im-home-im-happy" target="_self" bgImage="im-here-im-home-im-happy"/>
    <HomeCard title="Cork Queeros" text="" url="https://corklgbtarchive.com/cork-queeros/" target="_blank" bgImage="cork-queeros"/>
    <HomeCard title="Orla Egan" text="" url="/#/about" target="_self" bgImage="orla"/>
</section>