<div class="orlaBgImage"></div>


<h2>About - Orla Egan</h2>
<hr>

<p>Orla Egan is a <strong>Queer Archival Activist</strong> and has been active in the Cork LGBT community since the 1980s. She has a long history of LGBT activism and work in the community development, education, equality and social justice fields. </p>

<p>She is the Founder and Director of <strong>Cork LGBT Archive</strong>.</p>

<p>She is the author of <strong>Queer Republic of Cork</strong>, Cork Lesbian, Gay, Bisexual and Transgender Communities 1970s-1990s (Onstream Publications 2016) and of the short play <strong>Leeside Lezzies</strong>.</p>

<p>She is the Director of the short Cork LGBT documentary film <strong>I'm Here, I'm Home, I'm Happy</strong> (2021)</p>

<p>Her new book, <strong>Diary of An Activist</strong>, an Irish graphic social activism memoir, produced in collaboration with Megan Luddy O'Leary, is published by Cork City Library in November 2022.</p>

<p>Orla has created and curated a number of <strong>exhibitions</strong>. The Cork LGBT History Exhibition has been displayed in various venues in Cork , Belfast and Berlin.   She created Cork Queeros photography exhibition in 2021, showcasing a wide range of Cork LGBT people.</p>

<p>Since 2019 she has been leading Cork LGBT History <strong>Walking Tours</strong> and in 2020 created the Cork LGBT Archive Interactive Walking Tour in conjunction with Cork Pride.</p>

<p>The Cork LGBT Archive received a <strong>Hidden Heritage Award</strong> from the Irish Heritage Council in 2016 and in 2019 won the inaugural <strong>Community Archives Award</strong> from the Digital Repository of Ireland. The Cork LGBT Archive was shortlisted for the <strong>GALAS Digital Change Maker Award</strong> in 2020.  The Cork LGBT Archive is one of six <strong>Heritage Finalists</strong> in the Irish National Lottery Good Causes Awards 2021.</p>

<p>Orla was awarded the <strong>UCC Athena Swan Equality Award</strong> in March 2021.</p>

<p>Orla has been nominated for a <strong>Cork Rebel Spirit Award</strong> in 2022.</p>

<p>Throughout the 1990s Orla Egan worked with the UCC Equality Committee and was Director of the HEA funded <strong>Higher Education Equality Unit</strong>, supporting and encouraging equality policies and practices in third level institutions throughout Ireland.  She was also involved with UCC <strong>Women's Studies</strong>, was Co-ordinator of the Adult Education Diploma in Women's Studies and Chair of the Adult Education Board of Women's Studies. </p>

<p>Orla is the proud parent of <a href="https://www.jacob-lily.net/" target="_blank">Jacob-Lily</a>.</p>

<style>
    .orlaBgImage {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        background: url("../media/homepage/orla.png");
        background-size: auto;
        content: "";
        z-index: -1;
        opacity: 0.1;
        background-size: cover;
    }

    h2 {
        font-size: 2.3rem;
        font-weight: bold;
    }
    hr { margin-bottom: 1rem; }
    p {
        margin-bottom: 1rem;
    }
    strong { font-style: italic; }
    a {
        cursor: pointer;
        color: #0093ff;
    }
    a:hover {
        text-decoration: dotted underline;
    }
</style>