
<main>
    <div class="loader"></div>
    <h3>please stand by, Capt'...</h3>
</main>

<style>
    main {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1.3rem;
    }
    .loader {
        width: 15px;
        height: 3px;
        border-radius: 50%;
    }
    @keyframes loaderIn {}


    .loader {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-top: 3rem;

        border: 16px solid transparent;
        border-top: 16px solid var(--primary);
        animation: loaderRotate 800ms linear infinite;
    }

    @keyframes loaderRotate {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>