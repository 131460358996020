<script>
	// import List from './List.svelte';
	import { onDestroy } from 'svelte';
	import { showHeader } from './common/stores.js';
    import Router from "svelte-spa-router";
	import {location} from 'svelte-spa-router';
    import routes from "./routes";
	import Header from "./common/Header.svelte";
	import Footer from "./common/Footer.svelte";

	// Dealing with Stores
	// let showHeaderValue;
	// const unsubscribe = showHeader.subscribe(value => {
	// 	showHeaderValue = value;
	// });
	showHeader.set(true);


	const __version = 'v1.0.0';
	// onDestroy(unsubscribe);
</script>


<main>
	<Header/>

	{#if !($location == '/')}
		<!-- All Other Pages -->
		<section class="flex flex-col max-w-[65ch] mx-auto pt-8 mt-8">
			<Router {routes}/>
		</section>
	{:else}
		<!-- Landingpage -->
		<section class="flex flex-col w-full m-0 p-0">
			<Router {routes}/>
		</section>
	{/if}

	<Footer/>
</main>