<div class="orlaBgImage"></div>


<h2>Contact Me - Orla Egan</h2>
<hr>
<p>Feel free to get in touch with me or follow me on social media :)</p>

<section class="flex flex-row flex-wrap gap-4 w-full justify-evenly items-center mt-8 mb-12">
    <a href="mailto:orla@orlaegancork.com" target="_blank">
        <img class="w-[80px]" src="/assets/media/socials/email.png" alt="Email Me">
    </a>
    <a href="https://twitter.com/orlaegan1" target="_blank">
        <img class="w-[80px]" src="/assets/media/socials/twitter.png" alt="Twitter">
    </a>
    <a href="https://mastodon.ie/@orlaegan" target="_blank">
        <img class="w-[80px]" src="/assets/media/socials/mastodon.png" alt="Mastodon">
    </a>
    <a href="https://www.instagram.com/orlaegan1/" target="_blank">
        <img class="w-[80px]" src="/assets/media/socials/instagram.png" alt="Instagram">
    </a>
</section>

<style>
    .orlaBgImage {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        background: url("../media/homepage/orla.png");
        background-size: auto;
        content: "";
        z-index: -1;
        opacity: 0.1;
        background-size: cover;
    }
    h2 {
        font-size: 2.3rem;
        font-weight: bold;
    }
    h3 {
        font-size: 1.8rem;
        font-weight: bold;
    }
    hr { margin-bottom: 1rem; }
    p {
       margin-bottom: 1rem;
    }
    strong { font-style: italic; }
    a {
        cursor: pointer;
        color: #0093ff;
    }
    a:hover {
        text-decoration: dotted underline;
    }
</style>