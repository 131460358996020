<div class="orlaBgImage"></div>

<h2>I'm Here, I'm Home, I'm Happy</h2>
<hr>

<p>New Cork LGBT film from Orla Egan and the Cork LGBT Archive.</p>

<p>What happens when you find your community, your tribe?</p>

<p>I'm Here, I'm Home, I'm Happy is the new film from the Cork LGBT Archive.</p>
<p>The film uses extracts from oral history interviews and items from the Cork LGBT Archive, to explore the lives of some LGBT people in Cork in the 1970s and 1980s. It explores how the Cork LGBT community provided a sense of connection, belonging, mutual support and fun to LGBT people at a time of criminalisation, prejudice and discrimination. The participants talk about the community spaces, parties, pubs, clubs and political activism. "It was fun, Cork in the 1980s was so much fun."</p>

<p>There is a definite positive slant to the film. There is a perception that it must have been awful to be LGBT in Ireland in the 1970s and 1980s. There were clear challenges, but the film focuses on was what happened when you found your community, found your tribe - the fundamental difference it made to LGBT people's lives and lived experiences. </p>

<p>It is important, especially for young LGBT people, that we have sense of our history; that we see the connections between where we came from and where we are now and how change happens; to see the pride, the fun, the strength, the resilience, resistance  - that it wasn't all negative.</p>

<p>The Cork LGBT Community were Agents of Change. We were central players in bringing about social change in Ireland and in the lived experience of LGBT people. We challenged the dominant narratives about what it meant to be lesbian, gay, bisexual, transgender: that we are not sick, sinners or criminals. Recent changes, like Marriage Equality and Gender Recognition Legislation, didn't happen out of the blue; they built on decades of activism, of people being brave and sassy and demanding equality and respect.</p>

<p>The LGBT Community coming together in face of prejudice and discrimination has been happening for a long time. This has resonance with what has been happening recently in response to increased homophobia and transphobia and with the murders of Aidan Moffitt and Michael Snee in Sligo and of Timmy Hourihane in Cork. In response to this the LGBT community came together, colourfully, visibly and with pride. We gathered in Bishop Lucy Park with pride flags flying and with the support of our allies, including the City Council and the Lord Mayor. Pride in the face of prejudice.</p>

<p>I'm Here, I'm Home, I'm Happy film was made as part of my work with the Cork LGBT Archive, that I set up to gather, preserve and share the rich history of Cork's lesbian, gay, bisexual and transgender communities. The physical collection (built on the Arthur Leahy Collection) is in Cork Public Museum in Fitzgerald's Park. There is a digital archive www.corklgbtarchive.com </p>
<p>I strive to create dynamic and interesting ways to engage people with this history, to bring the history to life, through the Queer Republic of Cork book, exhibitions, theatre, walking tours and now this film I'm Here, I'm Home, I'm Happy.</p>

<p>It is a low budget film - not Hollywood production standards - but it tells an important story that I feel deserves to be told. These are important stories to be gathered and shared; capturing stories and voices that could be lost. One of the voices we will hear in this film is that of Dave Roche - it is so wonderful and moving to see and hear Dave talking about the community he loved. These are only some of the many stories to be told. We are hoping to capture some more oral histories this summer with support of Heritage Council.</p>

<p>The film was made possible with the support of the Irish Heritage Council, Carol O'Keeffe, Eddie Noonan and Frameworks Films, Jacob-Lily, Mira.  I am especially grateful to Edmund Lynch who generously shared interviews with me - Edmund has interviewed over 300 Irish LGBT people over the past few years and allowed me to use footage from some of his Cork interviews.</p>

<h3>Film Screenings</h3>
<hr>
<table>
    <thead>
        <tr>
            <th>Date</th>
            <th>Location</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>4 May 2022</td>
            <td>Cork City, Library, Cork</td>
        </tr>
        <tr>
            <td>22-28 May 2022</td>
            <td>Fastnet Film Festival, Schull</td>
        </tr>
        <tr>
            <td>27 July 2022</td>
            <td>GAZE / Cork Pride, Cork</td>
        </tr>
        <tr>
            <td>23 September 2022</td>
            <td>Culture Night, University College Cork</td>
        </tr>
        <tr>
            <td>29 September 2022</td>
            <td>European Lesbian Conference, Budapest</td>
        </tr>
        <tr>
            <td>2 October 2022</td>
            <td>GAZE Film Festival, Dublin</td>
        </tr>
        <tr>
            <td>4 October 2022</td>
            <td>Indie Cork Film Festival, Cork</td>
        </tr>
    </tbody>
</table>

<style>
    .orlaBgImage {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        background: url("../media/homepage/im-here-im-home-im-happy.png");
        background-size: auto;
        content: "";
        z-index: -1;
        opacity: 0.1;
        background-size: cover;
    }


    h2 {
        font-size: 2.3rem;
        font-weight: bold;
    }
    h3 {
        font-size: 1.8rem;
        font-weight: bold;
    }
    hr { margin-bottom: 1rem; }
    p {
        margin-bottom: 1rem;
    }
    strong { font-style: italic; }
    a {
        cursor: pointer;
        color: #0093ff;
    }
    a:hover {
        text-decoration: dotted underline;
    }
    * {
        --al-gray: #cccccc;
        --al-dark-black: #1c1a1a;
        --al-super-light-gray: #f1f1f1;
    }
    table {
        width: 100%;
        margin-bottom: 1rem;
        caption-side: top;
        border-collapse: collapse;
        color: var(--al-table-border);
        --al-table-head: var(--al-gray);
        --al-table-border: var(--al-dark-black);
        --al-table-highlight: var(--al-super-light-gray);
        --al-table-highlight-2: var(--al-gray);
    }
    table.al-table-dark {
        --al-table-head: var(--al-dark-black);
        --al-table-border: var(--al-light-gray);
        --al-table-highlight: var(--al-super-light-black);
        --al-table-highlight-2: var(--al-light-black);
    }
    th, td { text-align: start; padding: 0.5rem; }

    :is(thead, tbody, tfoot, tr, th, td) {
        border: 0 solid inherit;
    }

    table > thead { vertical-align: bottom; }
    table > tbody { border-top: 1px solid var(--al-table-border); }

    thead > tr > :is(th, td) { background-color: var(--al-table-head); }

    tr:nth-child(odd) { background-color: var(--al-table-highlight); }
    tr:nth-child(even) { background-color: var(--al-table-highlight-2); }
</style>