<script>
    import {link} from 'svelte-spa-router'
</script>

<header class="flex flex-col md:flex-row w-full justify-between items-center px-[15%] mb-8">
    <a class="text-bold text-5xl text-center font-kavoon text-orla-red-100" href="/" use:link>Orla Egan</a>
    
    <ul class="flex flex-row justify-between items-center p-3">
        <li>
            <a class="focus:underline hover:opacity-60" href="/contact" use:link>Contact Me</a>
        </li>
        <li>
            <a class="ml-8 focus:underline hover:opacity-60" href="/about" use:link>About</a>
        </li>
    </ul>
</header>

<style>
    * {
      font-family: 'Kavoon', cursive;
    }
</style>