// import LoadingPage from "./pages/Loading.svelte";
// import LicensePage from "./pages/License.svelte";
// import ActionsPage from "./pages/Actions.svelte";
// import PlatformsPage from "./pages/Platforms.svelte";
// import PerformActionPage from "./pages/PerformAction.svelte";

// export default {
//     "/": LicensePage,
//     "/actions": ActionsPage,
//     "/setup/:action": PlatformsPage,
//     "/install": PerformActionPage,
//     "/repair": PerformActionPage,
//     "/uninstall": PerformActionPage,
//     "*": LoadingPage,
// };

import HomePage from "./pages/Home.svelte";
import ContactPage from "./pages/Contact.svelte";
import AboutPage from "./pages/About.svelte";
import LoadingPage from "./pages/Loading.svelte";
import PPage from "./pages/p.svelte";
import herehomehappyPage from "./pages/im-here-im-home-im-happy.svelte";

export default {
    "/": HomePage,
    // "/setup/:action": PlatformsPage,
    "/p/:item": PPage,
    "/im-here-im-home-im-happy": herehomehappyPage,
    "/contact": ContactPage,
    "/About": AboutPage,
    "*": LoadingPage
};