<script>
    import {link} from 'svelte-spa-router'

    export let title;
    export let text;
    export let url;
    export let target;
    export let bgImage;
    bgImage = "assets/media/homepage/" + bgImage + ".png";

    var posCenter = (title == "I'm Here, I'm Home, I'm Happy") ? "background-position: center;" : "";
</script>


<a href={url} target={target} class="block w-[48%] h-[24vh] sm:w-1/4 md:h-[27vw] 2xl:h-[20vw] content-none" style={`background: url("${bgImage}"); background-size: cover; ${posCenter}`}>
    <div class='imgCover'></div>
    <div>
        <h3 class="text-2xl mb-4">{title}</h3>
        <p>{text}</p>
    </div>
</a>


<style>
    a {
        position: relative;
        box-shadow: 0px 1.534px 29.9129px 11.505px rgba(0, 0, 0, 0.25);
        color: white;
    }
    a:hover {
        opacity: 1 !important;
        box-shadow: 0px 1.534px 20px 11.505px rgba(0, 0, 0, 0.25);
    }
    a:hover > div { visibility: visible; }

    .imgCover {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        width: 100%;
        height: 100%;
        z-index: 5;
    
        background: linear-gradient(
            to top,
            #0e1d36 0%,
            #0e1d36a6 50%,
            #0000ff00 95%,
            transparent 100%
        );
    }

    div {
        position: absolute;
        bottom: 0;
        padding-left: 1rem;
        visibility: hidden;
        text-align: left;
        transition: all 400ms ease-in-out;
        position: absolute;
        z-index: 6;
    }

    div > p  { display: inline-block; }
        
    div > span {
        color: #ef3aff;
        margin-bottom: 1rem;
    }
</style>